import React from 'react';
import { getIcon } from '../IconCard/icon-card.component';
import faces from './../../config/faces.json';
import styles from './cube.module.css';

const Cube = () => {
  return (
    <div className={styles.container}>
      <div className={styles.cube}>
        {faces.map((face, index) => (
          <div key={index} className={`${styles.face} ${styles[face.class]}`}>
            {getIcon(face.name, 'cubeIcon')}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Cube;