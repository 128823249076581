import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Particles from "react-particles";
import { loadFireflyPreset } from "tsparticles-preset-firefly";

import Cube from '../components/Cube/cube.component'
import IconCard from '../components/IconCard/icon-card.component';
import CardGroup from 'react-bootstrap/CardGroup';
import Footer from '../components/Footer/footer.component';

import styles from './app.module.css';
import particlesOptions from '../config/particles.json';
import Links from '../config/links.json';

async function customInit(engine) {
    // this adds the preset to tsParticles, you can safely use the
    await loadFireflyPreset(engine);
}

const options = {
    particles: {
        shape: {
            type: "circle", // starting from v2, this require the square shape script
        },
    },
    "background": {
        "color": "#282c34"
    },
    preset: "firefly",
}

function App() {
    return (
        <>
            <Particles options={options} init={customInit} />

            <Container>
                <Row>
                    <div className={styles.mainCard}>
                        <Cube />
                    </div>
                </Row>


                <div className={styles.cardGroupWrapper}>
                    <CardGroup className={styles.cardGroup}>
                        {Links.map((link, index) => (
                            <IconCard key={index} title={link.title} url={link.url} icon={link.icon} />
                        ))}
                    </CardGroup>
                </div>
                <Footer />
            </Container>

        </>
    );
}

export default App;
