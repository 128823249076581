import styles from './footer.module.css'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className={styles.footer}>
      <p className="text-end px-2">Colum Bennett &copy; {year}</p>
    </div>
  )
}

export default Footer;