import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaSquareXTwitter, FaRebel, FaSquareGithub, FaLinkedin } from 'react-icons/fa6'
import { MdEmail } from 'react-icons/md';
import { ImBlog } from 'react-icons/im';

import styles from './icon-card.module.css';

export function getIcon(icon, style) {
  switch (icon.toLowerCase()) {
    case 'linkedin':
      return <FaLinkedin className={`${styles[style]}`} />;
    case 'github':
      return <FaSquareGithub className={`${styles[style]}`} />;
    case 'twitter':
      return <FaSquareXTwitter className={`${styles[style]}`} />;
    case 'blog':
      return <ImBlog className={`${styles[style]}`} />;
    case 'email':
      return <MdEmail className={`${styles[style]}`} />;
    case 'rebel':
      return <FaRebel className={`${styles[style]}`} />;
    default:
      return <FaRebel className={`${styles[style]}`} />;
  }
}

const IconCard = (props) => {
  const { title, url, icon } = props;
  return (
    <a className={styles.iconCard} href={url} target="_blank" rel="noreferrer">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`${icon}- id`}>{title}</Tooltip>}
      >
        <Card className={styles.mainCard}>
          <Card.Body>{getIcon(icon, 'icon')}</Card.Body>
        </Card>
      </OverlayTrigger>
    </a>
  )
}

export default IconCard;